import { Form, message, Space } from "antd";
import {
	Accordion,
	CancelBtn,
	EditBtn,
	FormItem,
	InputNumberField,
	ProjectQuerySelect,
	SaveBtn,
	StandardForm,
} from "components";
import { TableLayout } from "components/ETable/components/TableComponents";
import { CONFIG_REWARD, CONFIG_SET_OF_METRICS } from "constants/api";
import { CONFIG_UNIT, VND } from "constants/setting";
import { useFetchWithUrlParams, useUrlParams } from "hooks";
import CustomLayout from "layout";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { addCurrency, addPercent } from "utils";
import { TAB_KEYS, TABS_PROPS } from "../constants";
import { FlexColumn } from "./components";
import MetricTable from "./components/MetricTable";
import { DEFAULT_METRICS } from "./constants";
import { MetricTableResponse } from "./types";
import { handleMetricList, postMetrics } from "./utils";

function ProjectMetrics() {
	const [editing, setEditing] = useState(false);
	const [form] = Form.useForm();
	const { t: metricT } = useTranslation("metrics");
	const { t } = useTranslation();
	const { tab, urlParams } = useUrlParams();
	const isSpecific = tab === TAB_KEYS[1];
	const {
		data,
		loading,
		refetch: refetchMetrics,
	} = useFetchWithUrlParams<{}, MetricTableResponse[]>({
		url: CONFIG_SET_OF_METRICS,
	});
	const { data: rewardRateInProjectDuration, refetch: refetchReward } =
		useFetchWithUrlParams<{}, number>({
			url: CONFIG_REWARD,
		});

	const refetch = useCallback(() => {
		refetchMetrics();
		refetchReward();
	}, [refetchMetrics, refetchReward]);

	const cancelEdit = useCallback(() => {
		form.resetFields();
		setEditing(false);
	}, [form]);

	useEffect(() => {
		setEditing(false);
	}, [isSpecific]);

	const handledData = useMemo(() => handleMetricList(data), [data]);

	useEffect(() => {
		if (
			Array.isArray(handledData.metricData) &&
			handledData.metricData.length > 0
		) {
			form.setFieldsValue({
				metricsRequests: handledData.metricData,
				rewardRateInProjectDuration,
			});
		} else {
			form.setFieldsValue({ metricsRequests: DEFAULT_METRICS });
		}
	}, [handledData, form, rewardRateInProjectDuration]);

	const handleSubmit = useCallback(
		async (values: any) => {
			try {
				await postMetrics(values, urlParams?.projectKey as string);
				message.success(
					t("msg.success", {
						action: t("function.edit"),
					})
				);
				cancelEdit();
				refetch();
			} catch (error) {
				message.error((error as any)?.message);
			}
		},
		[t, refetch, cancelEdit, urlParams?.projectKey]
	);

	return (
		<CustomLayout
			tabProps={TABS_PROPS}
			currentPageLabel={metricT("projectMetrics")}
		>
			<StandardForm form={form} onFinish={handleSubmit}>
				<TableLayout
					header={
						<FlexColumn gap="0.625rem">
							{isSpecific && <ProjectQuerySelect />}
							{!editing ? (
								<EditBtn
									onClick={() => {
										setEditing(true);
									}}
								/>
							) : (
								<Space>
									<SaveBtn />
									<CancelBtn danger onClick={cancelEdit} />
								</Space>
							)}
						</FlexColumn>
					}
					fullScreen={{} as any}
				>
					<Accordion
						defaultActiveKey={[1, 2]}
						items={[
							{
								title: metricT("panelTitle.evaluation"),
								key: 1,
								info: `${metricT("info.evaluation")} ${
									isSpecific ? "" : metricT("info.iconStar")
								}`,
								children: (
									<MetricTable
										data={handledData}
										loading={loading}
										isEditing={editing}
										name="metricsRequests"
										isSpecific={isSpecific}
									/>
								),
							},
							{
								title: metricT("panelTitle.reward"),
								key: 2,
								info: metricT("info.reward"),
								children: (
									<FlexColumn>
										<FormItem
											label={metricT("info.rewardRate")}
											forView={!editing}
											renderViewMode={() =>
												addPercent(rewardRateInProjectDuration)
											}
											name="rewardRateInProjectDuration"
											rules={[
												{
													required: true,
													message: t("form:validateMsg.commonRequired"),
												},
											]}
										>
											<InputNumberField
												addonAfter={CONFIG_UNIT[1]}
												min={0}
												max={100}
											/>
										</FormItem>
										{!isSpecific && (
											<FormItem
												label={metricT("info.rewardMoney")}
												forView={!editing}
												renderViewMode={(value: any) =>
													addCurrency(value, false)
												}
												name="rewardMoney"
											>
												<InputNumberField addonAfter={VND} />
											</FormItem>
										)}
									</FlexColumn>
								),
							},
						]}
					></Accordion>
				</TableLayout>
			</StandardForm>
		</CustomLayout>
	);
}

export default ProjectMetrics;
