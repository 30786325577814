import styled from "@emotion/styled";
import { Form } from "antd";
import { NamePath } from "antd/lib/form/interface";
import { AddBtn, DeleteBtn, FormItem } from "components";
import { translate } from "utils";
import { TNestedEditingColsProps } from "../columns";
import { getCol } from "../utils";
import { MCell, MNestedRow, MRow } from "./TableComponents";

type IEditingRowProps = {
	parentName?: NamePath;
	name: NamePath;
	cols: TNestedEditingColsProps;
	editable?: boolean;
	isSpecific?: boolean;
};

const AddCell = styled(MCell)`
	padding-top: 0;
	text-align: center;
`;

function EditingRow({
	name,
	cols,
	parentName,
	editable,
	isSpecific,
}: IEditingRowProps) {
	return (
		<Form.List name={name}>
			{(fields, { remove, add }) => {
				return (
					<MNestedRow>
						{fields.map(({ name: childName, key, ...field }) => {
							const newParentName = parentName
								? Array.isArray(parentName)
									? parentName.concat(name)
									: [parentName].concat(name)
								: name;

							const bordered = !(editable && childName === fields.length - 1);
							const rowElements = cols.map((col) => {
								const cellName = [childName, col.name];
								if ("children" in col) {
									return (
										<EditingRow
											parentName={newParentName}
											key={col.name}
											name={cellName}
											cols={col.children}
											editable={col.name === "details"}
											isSpecific={isSpecific}
										/>
									);
								}

								if (col.hiddenInCommon && !isSpecific) {
									return null;
								}

								return (
									<MCell
										key={col.name}
										width={getCol(col.name).width}
										bordered={bordered}
									>
										<FormItem
											name={cellName}
											parentName={newParentName}
											noStyle
											forView={col.forView}
											rules={
												col.required
													? [
															{
																required: true,
																message: translate(
																	"form:validateMsg.commonRequired"
																),
															},
													  ]
													: []
											}
											shouldUpdate
											{...field}
										>
											{col.component}
										</FormItem>
									</MCell>
								);
							});

							if (editable) {
								rowElements.push(
									<MCell
										key="action"
										width={getCol("action").width}
										bordered={bordered}
									>
										<DeleteBtn
											noText
											disabled={fields.length === 1}
											onClick={() => {
												remove(childName);
											}}
										/>
									</MCell>
								);
							}

							return <MRow key={key}>{rowElements}</MRow>;
						})}
						{editable && (
							<AddCell>
								<AddBtn
									onClick={() => {
										add();
									}}
								/>
							</AddCell>
						)}
					</MNestedRow>
				);
			}}
		</Form.List>
	);
}

export { EditingRow };
