// Work log
export const MY_WORKLOG = "/logwork/";
export const LOGWORK_INDIVIDUAL = "/logwork/individual";
export const PROJECT_LOGWORK = "/project/worklog/";

//Dropdown
export const DROPDOWN_MY_PROJECT = "dropdown/project/joined-project";
export const DROPDOWN_PROJECT = "dropdown/project";
export const DROPDOWN_PROJECT_SYNC = "dropdown/project/sync";
export const DROPDOWN_POSITION = "dropdown/position";
export const DROPDOWN_RANK = "dropdown/rank";
export const DROPDOWN_CONTRACT_TYPE = "dropdown/contract-type";
export const DROPDOWN_COMPONENT = "dropdown/component";
export const DROPDOWN_PROJECT_PM = 'dropdown/project-pm';
export const DROPDOWN_PROJECT_MANARGER ="dropdown/project-manage";
export const DROPDOWN_MEMBER = "member";
export const DROPDOWN_STATUS_PLAN = "project/action-status";

//Chart
export const MY_CHART = "chart/me";
export const MY_CHART_SUMMARY = "chart/me/summary";
export const CHART_PROJECT = "chart/admin/project/";
export const CHART_PROJECT_SUMMARY = "chart/admin/project/summary";
export const CHART_MEMBERS_DETAIL = "chart/detail";
export const CHART_ALL_MEMBERS = "chart/admin/all-members/summary";
export const CHART_ALL_MEMBERS_BY_PROJECT = "chart/admin/all-members";
export const CHART_ADMIN_ALL_MEMBERS = "chart/admin/all-members/";
export const urlGetLogtimeUser = (username: string) =>
	`${CHART_ADMIN_ALL_MEMBERS}${username}/summary`;

// Begin: Plan
export const FREE_RESOURCE = "/resource/";
export const PLAN_PROJECT = "project/effort";

export const PLAN_GENERAL = "project/general";
export const PLAN_GENERAL_DURATION = "project/general/duration";
export const PLAN_ALLOCATION = "plan/allocation";
export const PLAN_ALLOCATION_TABLE_HEADER = "plan/allocation/actual";
export const PLAN_BILLABLE = "plan/billable";
export const PLAN_BILLABLE_TABLE_HEADER = "plan/billable/actual";
// End: Plan

//ProjectJiraSync
export const CONFIG_SYNCED_PROJECTS = "/sync/projects";
export const SYNC_PROJECT = "/sync/project";
export const SYNC_PROJECT_DETAIL = "/sync/project/detail/";
export const ADD_STOP_SYNC = '/dropdown/project/stopped';
export const PUT_STOP_SYNC = '/project/stop-project';
export const RESYNC = '/project/resync-project'

//Sync api
export const SYNC_URL = "/save-projects";
export const SYNC_HISTORY = "/sync/list";

//Config api
export const CONFIG_REWARD = "/config/reward-project";
export const CONFIG_SET_OF_METRICS = "/metrics/project-general-list";
export const POST_SET_METRICS = "/metrics/project-general";
export const GET_MEMBER_METRICS = "/metrics/member-general";
export const EDIT_ROLE_FACTOR = "/role-factor/";
export const EDIT_TEAM_EVALUATION = "/team-evaluation/";

//export api
export const SYNC_PROJECT_DETAIL_EXPORT = "/export/project-sync-detail/";
export const MY_WORKLOG_EXPORT = "/export/my-worklog/";
export const PROJECT_LOGWORK_EXPORT = "/export/project-worklog";
export const FREE_RESOURCE_EXPORT = "/export/free-resource";
export const PLAN_PROJECT_EXPORT = "/export/project-effort";
export const PLAN_ALLOCATION_EXPORT = "/export/allocation";
export const PLAN_BILLABLE_EXPORT = "/export/billable";
