import axiosClient from "api";
import { POST_SET_METRICS } from "constants/api";
import { numRender } from "../components/MetricTable/utils";
import { INFINITE } from "../constants";
import {
	MetricResponse,
	MetricTableResponse,
	TMetricForm,
	TPostMetrics,
} from "../types";

export const handleMetricList = (data?: MetricTableResponse[]) => {
	const handledData: MetricTableResponse[] = [];
	let totalWeight = 0;
	if (data) {
		const types = new Set<string>();
		data.forEach(({ metricsResponses, type, weight }) => {
			if (!types.has(type)) {
				types.add(type);
				const metrics = new Set<string>();
				const metricRes: MetricResponse[] = [];
				metricsResponses?.forEach(({ details, ...metricResponse }) => {
					if (!metrics.has(metricResponse.metric)) {
						metrics.add(metricResponse.metric);
						metricRes.push({
							...metricResponse,
							details: details?.map(({ min, max, score }) => ({
								score,
								min: numRender(min),
								max: numRender(max),
							})),
						});
					}
				});
				let numWeight: number | undefined = +(weight as any);
				numWeight = Number.isNaN(numWeight) ? undefined : numWeight;
				totalWeight += numWeight ?? 0;
				handledData.push({
					metricsResponses: metricRes,
					type,
					weight: numWeight,
				});
			}
		});
	}
	return {
		metricData: handledData,
		totalWeight,
	};
};

export const postMetrics = async (values: TMetricForm, projectKey?: string) => {
	let metricsRequests: TPostMetrics["metricsRequests"] = [];
	const ids: Array<string | number> = [];
	values.metricsRequests.forEach(({ metricsResponses, type }) => {
		metricsRequests = metricsRequests.concat(
			metricsResponses.map(({ metric, id, details, ...metricProps }) => {
				if (id) {
					ids.push(id);
				}
				return {
					...metricProps,
					projectKey,
					details: details.map(({ score, min, max }) => ({
						score,
						min: min ?? -INFINITE,
						max: max ?? INFINITE,
					})),
					metricCriteria: metric,
					category: type,
				};
			})
		);
	});
	const result = {
		...values,
		metricsRequests,
	};
	if (ids.length > 0) {
		return await axiosClient.put(POST_SET_METRICS, result, {
			params: {
				ids,
			},
		});
	}
	return await axiosClient.post(POST_SET_METRICS, result);
};
