import { TinyColor } from "@ctrl/tinycolor";
import { Theme, ThemeProvider } from "@emotion/react";
import React from "react";
import GlobalStyles from "./GlobalStyles";

const Primary = new TinyColor("#1890ff");
const Text = new TinyColor("rgba(0, 0, 0, 0.85)");
const Background = new TinyColor("white");
const hover = {
	light: "#f1f1f1",
	medium: "",
	dark: "",
};
const PrimaryClone = Primary.clone();
const contrast = Math.abs(Primary.getLuminance() - Background.getLuminance());
let newPrimary = PrimaryClone.clone();
if (contrast <= 0.25) {
	if (Background.isDark()) {
		newPrimary = PrimaryClone.brighten((0.5 - contrast) * 100);
	} else {
		newPrimary = PrimaryClone.brighten((contrast - 0.5) * 100);
	}
} else if (contrast <= 0.75) {
} else {
	if (Background.isLight()) {
		PrimaryClone.brighten((0.5 - contrast) * 100);
	} else {
		PrimaryClone.brighten((contrast - 0.5) * 100);
	}
}
hover.light = newPrimary.lighten(40).toString();
hover.medium = newPrimary.lighten(30).toString();
hover.dark = newPrimary.lighten(27).toString();

export const theme: Theme = {
	backgroundColor: Background.toString(),
	color: {
		primary: Primary.toString(),
		danger: "#FF4D4F",
		text: Text.toString(),
		border: "#f0f0f0",
	},
	hover,
};

const AppThemeProvider = ({ children }: { children: React.ReactElement }) => {
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyles />
			{children}
		</ThemeProvider>
	);
};

export default AppThemeProvider;
