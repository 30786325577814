import styled from "@emotion/styled";
import { InputNumber, InputNumberProps } from "antd";
import { SPECIAL_KEYS } from "constants/setting";
import { CSSProperties } from "react";
import { concatString } from "utils";

type StyledInputNumberProps = {
	width?: CSSProperties["width"];
};

export const StyledInput = styled(InputNumber)<StyledInputNumberProps>`
	width: ${({ width }) => width};
`;

interface IInputNumberFieldProps
	extends StyledInputNumberProps,
		Omit<InputNumberProps, "width"> {
	block?: boolean;
	testId?: string;
}

function InputNumberField({
	block = true,
	width,
	onKeyDown,
	id,
	testId,
	...props
}: IInputNumberFieldProps) {
	return (
		<StyledInput
			width={block ? "100%" : width}
			onKeyDown={(e) => {
				if (!(SPECIAL_KEYS.includes(e.key as any) || /[\d.-]/g.test(e.key))) {
					e.preventDefault();
				}
				onKeyDown?.(e);
			}}
			id={id}
			data-testid={concatString(id ?? testId, "input-number")}
			{...props}
		/>
	);
}

export { InputNumberField };
