import styled from "@emotion/styled";
import { Form, FormItemProps, Input, InputProps } from "antd";
import { NamePath } from "antd/lib/form/interface";
import { TextAreaProps } from "antd/lib/input";
import { FormItem } from "components";
import { MAX_INPUT_LEN } from "constants/rules";
import React from "react";
import { useTranslation } from "react-i18next";
import { Translation } from "translations";
import { ExtendFormItemProps, ExtendItemProps } from "types/forms/formFields";
import { disableFutureDate, concatString } from "utils";
import { RangeField, TRangeFieldProps } from "./RangeField";
const { useFormInstance } = Form;

export type IFormField<
	T,
	P extends keyof FormItemProps<any> = "name" | "label" | "rules" | "noStyle"
> = T & ExtendItemProps<P>;

export interface InputBoxProps
	extends ExtendFormItemProps<Omit<InputProps, "name">> {
	inputType?: "input";
}

export interface TextBoxProps
	extends ExtendFormItemProps<Omit<TextAreaProps, "name">> {
	inputType: "textarea";
}

export type InputFieldProps = InputBoxProps | TextBoxProps;

export const InputField = React.memo(
	({
		name,
		fieldProps,
		noStyle,
		label,
		rules,
		required,
		...props
	}: InputFieldProps) => {
		const form = useFormInstance();
		const defaultProps = {
			maxLength: MAX_INPUT_LEN,
			onBlur: (e: any) => {
				const { value } = e.target;
				if (value && name) {
					form.setFieldValue(name, value.trim());
				}
			},
			"data-testid": concatString(name, "input"),
		};
		if (props.inputType === "textarea") {
			const { inputType, ...restProps } = props;
			return (
				<FormItem
					noStyle={noStyle}
					label={label}
					name={name}
					rules={rules}
					required={required}
					{...fieldProps}
				>
					<Input.TextArea
						autoSize={{ minRows: 3 }}
						{...defaultProps}
						{...restProps}
					/>
				</FormItem>
			);
		} else {
			const { inputType, ...restProps } = props;
			return (
				<FormItem
					noStyle={noStyle}
					label={label}
					name={name}
					rules={rules}
					required={required}
					{...fieldProps}
				>
					<Input allowClear {...defaultProps} {...restProps} />
				</FormItem>
			);
		}
	}
);

type SearchPlaceholderKey =
	keyof Translation["translation"]["placeholder"]["searchInfo"];

const SearchBox = styled(InputField)`
	width: 15rem;
`;

export const SearchInput = React.memo(
	({
		name = "keyWord",
		rules,
		fieldProps,
		placeholder = "default",
		...props
	}: Omit<InputBoxProps, "name" | "inputType" | "placeholder"> & {
		name?: NamePath;
		placeholder?: SearchPlaceholderKey;
	}) => {
		const { t } = useTranslation();
		return (
			<SearchBox
				inputType="input"
				label={t("function.search")}
				rules={[
					{
						min: 2,
					},
				]}
				name={name}
				fieldProps={{
					help: "",
					validateStatus: "",
					...fieldProps,
				}}
				{...props}
				placeholder={t("placeholder.searchInfo." + placeholder)}
			/>
		);
	}
);

export type TDateRangeFieldProps = Omit<TRangeFieldProps, "name"> & {
	name?: string;
};

export const DateRangeField = React.memo(
	({
		name,
		noStyle,
		label,
		fieldProps,
		rules,
		...props
	}: TDateRangeFieldProps) => {
		const { t } = useTranslation("translation", {
			keyPrefix: "function",
		});
		return (
			<RangeField
				noStyle={noStyle}
				name={name || "time"}
				label={label ?? t("time")}
				rules={rules}
				disabledDate={disableFutureDate}
				fieldProps={fieldProps}
				{...props}
			/>
		);
	}
);

export * from "./ApiSelect";
export { default as CommonFilterForm } from "./CommonFilterForm";
export * from "./ProjectSelect";
export * from "./SomethingSelect";
export * from "./Radio";
export { RangeField };
