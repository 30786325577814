import styled from "@emotion/styled";
import { Form, Tooltip } from "antd";
import { TooltipParagraph } from "components/Typography";
import { ReactNode } from "react";

interface EditableBox {
	isEditing?: boolean;
	hasError?: boolean;
}

export const ErrorBox = styled.div<EditableBox>`
	position: relative;
	display: flex;
	align-items: center;
	transition: all 0.3s;
	.ant-input,
	.ant-select-selector,
	.ant-select-selector,
	.ant-input-number {
		border-color: ${({ hasError, theme }) =>
			hasError ? theme.color.danger : ""}!important;
	}
`;

export const EditableCell = ({
	value,
	isEditing,
	id,
	children,
}: {
	id: string | number;
	value?: string;
	isEditing?: boolean;
	children?: ReactNode;
}) => {
	return (
		<Form.Item noStyle shouldUpdate>
			{({ getFieldError, getFieldValue }) => {
				const namePath = id;
				const error = getFieldError(namePath)?.[0];
				return (
					<ErrorBox isEditing={isEditing} hasError={error?.length > 0}>
						{isEditing ? (
							<Tooltip title={error} defaultVisible>
								{children}
							</Tooltip>
						) : (
							<TooltipParagraph
								value={getFieldValue(namePath) || value}
								row={1}
							/>
						)}
					</ErrorBox>
				);
			}}
		</Form.Item>
	);
};
