import { Empty, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { addPercent } from "utils";
import { MetricTableResponse } from "../../types";
import { editingCols } from "./columns";
import {
	EditingRow,
	MFooter,
	MHead,
	MHeader,
	MRow,
	MTable,
} from "./components";
import { getCol, renderNestedRows } from "./utils";

type IMetricTableProps = {
	data: { metricData: MetricTableResponse[]; totalWeight: number };
	loading?: boolean;
	isEditing?: boolean;
	name: string;
	isSpecific?: boolean;
};

function MetricTable({
	name,
	data: { metricData, totalWeight },
	loading,
	isEditing,
	isSpecific,
}: IMetricTableProps) {
	const { t: translate } = useTranslation();
	const { t } = useTranslation("metrics", {
		keyPrefix: "evaluationTable",
	});

	return (
		<Spin spinning={loading}>
			<MTable>
				<MRow>
					{isEditing ? (
						<MHeader t={t} type="category" required />
					) : (
						<>
							<MHeader t={t} type="type" />
							<MHeader t={t} type="weight" />
							<MHeader t={t} type="metric" />
						</>
					)}
					<MHeader t={t} type="unit" required={isEditing} />
					<MHeader t={t} type="description" />
					{isSpecific && <MHeader t={t} type="status" />}
					<MHeader t={t} type="min" />
					<MHeader t={t} type="max" />
					<MHeader t={t} type="score" required={isEditing} />
					{isEditing && <MHead {...getCol("action")} />}
				</MRow>
				{isEditing ? (
					<EditingRow name={name} cols={editingCols} isSpecific={isSpecific} />
				) : metricData?.length ? (
					<>
						{renderNestedRows(metricData, undefined, isSpecific)}
						<MRow>
							<MFooter width={getCol("type").width}>
								{translate("metrics:sum")}
							</MFooter>
							<MFooter>{addPercent(totalWeight)}</MFooter>
						</MRow>
					</>
				) : (
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				)}
			</MTable>
		</Spin>
	);
}

export default MetricTable;
