import styled from "@emotion/styled";
import { Tabs, TabsProps } from "antd";
import { TAB_NAME } from "constants/format";
import { useUrlParams } from "hooks";
import React, { useEffect, useMemo } from "react";
import useGetPermission from "./useGetPermission";
import { PERMISSION } from "../constants/permissionMap";

export interface INavigateTabProps extends Omit<TabsProps, "items"> {
	name?: string;
	items?: Readonly<
		{
			key: React.Key;
			tab: string;
			children?: React.ReactElement;
		}[]
	>;
}

const TabList = styled(Tabs)`
	.ant-tabs-nav {
		margin-bottom: 0;
	}
	.ant-tabs-tab-btn {
		text-transform: capitalize;
	}
`;

function useCreateNavigateTabs({
	onChange,
	name = TAB_NAME,
	items,
	...props
}: INavigateTabProps) {
	const { tab, setUrlParams } = useUrlParams(undefined, name);
	const { tabs } = useGetPermission();

	useEffect(() => {
		if (items?.[0] && !tab) {
			setUrlParams({
				[name]: items[0].key,
			});
		}
		//eslint-disable-next-line
	}, []);

	const children = useMemo(() => {
		if (items) {
			const TabPanes: Record<string, React.ReactNode> = {};
			const TabButtons = items.map(({ children, key, ...item }) => {
				const tabPermission = tabs[key];
				TabPanes[key] = children ? React.cloneElement(children, {
					permissions: tabPermission
				}) : children;
				return <Tabs.TabPane key={key} {...item} disabled={!tabPermission?.includes(PERMISSION.VIEW)} />;
			});
			return {
				TabButtons,
				TabPanes,
			};
		}
		return {
			TabButtons: [],
			TabPanes: {},
		};
	}, [items, tabs]);

	return {
		Tabs: (
			<TabList
				activeKey={tab as string | undefined}
				onChange={(key) => {
					if (typeof onChange === "function") {
						onChange(key);
					}
					setUrlParams(
						{
							[name]: key,
						},
						true,
						{
							replace: false,
						}
					);
				}}
				{...props}
			>
				{children.TabButtons}
			</TabList>
		),
		Children: tab ? children.TabPanes[tab as any] : undefined,
	};
}

export { useCreateNavigateTabs };
