import { CONFIG_UNIT } from "constants/setting";
import { MetricTableResponse } from "./types";

export const METRIC_CATEGORIES = ["Quality", "Efficiency", "Business"] as const;

export const INFINITE = 2147483647;

export const DEFAULT_METRICS: MetricTableResponse[] = [
	{
		type: METRIC_CATEGORIES[0],
		weight: "",
		metricsResponses: [
			{
				metric: "",
				unit: CONFIG_UNIT[0],
				type: "",
				description: "",
				details: [
					{
						min: undefined,
						max: undefined,
						score: 0,
					},
				],
			},
			{
				metric: "",
				unit: CONFIG_UNIT[0],
				type: "",
				description: "",
				details: [
					{
						min: undefined,
						max: undefined,
						score: 0,
					},
				],
			},
			{
				metric: "",
				unit: CONFIG_UNIT[0],
				type: "",
				description: "",
				details: [
					{
						min: undefined,
						max: undefined,
						score: 0,
					},
				],
			},
		],
	},
	{
		type: METRIC_CATEGORIES[1],
		weight: "",
		metricsResponses: [
			{
				metric: "",
				unit: CONFIG_UNIT[1],
				type: "",
				description: "",
				details: [
					{
						min: undefined,
						max: undefined,
						score: 0,
					},
				],
			},
			{
				metric: "",
				unit: CONFIG_UNIT[1],
				type: "",
				description: "",
				details: [
					{
						min: undefined,
						max: undefined,
						score: 0,
					},
				],
			},
		],
	},
	{
		type: METRIC_CATEGORIES[2],
		weight: "",
		metricsResponses: [
			{
				metric: "",
				unit: CONFIG_UNIT[0],
				type: "",
				description: "",
				details: [
					{
						min: undefined,
						max: undefined,
						score: 0,
					},
				],
			},
		],
	},
];
