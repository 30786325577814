import { Input } from "antd";
import { InputNumberField } from "components";
import { MAX_INPUT_LEN } from "constants/rules";
import { StatusSelect } from "pages/WorkLog/components";
import { ReactNode } from "react";
import { Translation } from "translations";
import { addPercent } from "utils";
import { UnitSelect } from "../Select";
import { TMCell } from "./components";
import { numRender } from "./utils";

type ColKey = keyof Translation["metrics"]["evaluationTable"];
export type TColName = string & Omit<string, ColKey>;

interface TColProps extends TMCell {
	render?: (value: any, thisRow: Record<string, any>) => ReactNode;
}

/**
 * columns's width in rem
 */

const numColProps: TColProps = {
	order: 1,
	width: 9.375,
	textAlign: "center",
	render: numRender,
};

export const colProps: Record<TColName, TColProps> = {
	category: { width: 21.875 },
	type: { width: 9.375 },
	weight: {
		width: 7.5,
		render: addPercent,
	},
	metric: { width: 12.5 },
	unit: { width: 7.5 },
	description: { minWidth: "12.5rem" },
	status: { width: 9.375, textAlign: "center" },
	range: { width: 12.5, textAlign: "center" },
	max: numColProps,
	min: numColProps,
	score: { width: 9.375, textAlign: "center", order: 2 },
	action: { width: 3.5, textAlign: "center", order: 3 },
} as const;

type EditingColProps = {
	name: string;
	component?: ReactNode;
	forView?: boolean;
	required?: boolean;
	hiddenInCommon?: boolean;
};

type NestedEditingColProps = {
	name: string;
	children: Array<NestedEditingColProps | EditingColProps>;
};

export type TNestedEditingColsProps = Array<
	NestedEditingColProps | EditingColProps
>;

export const editingCols: TNestedEditingColsProps = [
	{ name: "type", forView: true },
	{
		name: "metricsResponses",
		children: [
			{
				name: "metric",
				component: <Input maxLength={15} />,
				required: true,
			},
			{
				name: "unit",
				component: <UnitSelect />,
				required: true,
			},
			{
				name: "description",
				component: (
					<Input.TextArea
						maxLength={MAX_INPUT_LEN}
						autoSize={{
							minRows: 1,
							maxRows: 5,
						}}
					/>
				),
			},
			{
				name: "status",
				component: <StatusSelect />,
				hiddenInCommon: true,
				// required: true,
			},
			{
				name: "details",
				children: [
					{
						name: "min",
						component: <InputNumberField />,
					},
					{
						name: "max",
						component: <InputNumberField />,
					},
					{
						name: "score",
						component: <InputNumberField />,
						required: true,
					},
				],
			},
		],
	},
];
