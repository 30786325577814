import styled from "@emotion/styled";
import { CSSProperties } from "react";
import { TColName } from "../columns";
import { getCol } from "../utils";

export type TMCell = {
	width?: string | number;
	order?: number;
	minWidth?: string | number;
	textAlign?: CSSProperties["textAlign"];
	bordered?: boolean;
};

export const MRow = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
`;

export const MCell = styled("div", {
	shouldForwardProp: (propName) =>
		!["width", "order", "minWidth", "textAlign", "bordered"].includes(propName),
})<TMCell>`
	${({ width }) => (width ? `width: ${width}` : "flex: 2")};
	text-align: ${({ textAlign }) => textAlign ?? ""};
	flex-shrink: 0;
	border-width: 0;
	min-width: ${({ minWidth = "" }) => minWidth};
	order: ${({ order }) => order ?? ""};
	border-bottom: ${({ bordered = true, theme }) =>
		bordered ? `1px solid ${theme.color.border}` : "none"};
	display: block;
	padding: 0.75rem;
	word-break: break-word;
`;

export const MHead = styled(MCell)`
	text-align: center;
	font-weight: 500;
	position: relative;
	background-color: rgb(249 250 251);
	&::first-letter {
		text-transform: uppercase;
	}
	&::before {
		background-color: rgb(229 231 235);
		content: "";
		height: 50%;
		position: absolute;
		right: 0;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		width: 1px;
	}
`;

export const MTable = styled.div`
	width: 100%;
	overflow-x: auto;
`;

export const MFooter = styled(MCell)`
	font-weight: 700;
	text-transform: uppercase;
`;

export const MNestedRow = styled.ul`
	order: 100;
	display: flex;
	flex: 1 1;
	flex-direction: column;
	margin: 0;
	padding: 0;
`;

export const MHeader = ({
	type,
	t,
	required,
}: {
	type: TColName;
	t: Function;
	required?: boolean;
}) => {
	const { render, ...colProps } = getCol(type);
	return (
		<MHead {...colProps} className={required ? "ant-form-item-required" : ""}>
			{t(type)}
		</MHead>
	);
};
