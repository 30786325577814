import type {
	AxiosError,
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
} from "axios";
import axios from "axios";
import { Token } from "localStorage";
import { objToUrlParams } from "utils";

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
	config.headers = {
		"Content-Type": "application/json",
		Authorization: `Bearer ${Token.get()}`,
	};
	return config;
};

const onResponse = (response: AxiosResponse) => {
	if (response.status === 200) {
		return response;
	}
	return Promise.reject(response);
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
	return Promise.reject(error);
};

const axiosClient: AxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	// baseURL: "http://192.168.1.56:8085", //Khanh
	// baseURL: "http://192.168.1.51:8085", //Tien
	// baseURL: "http://192.168.1.10:8085", //Tùng
	headers: {
		"content-type": "application/json",
	},
	paramsSerializer: objToUrlParams,
});

const authService: AxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_AUTH_URL,
	headers: {
		"content-type": "application/json",
	},
	paramsSerializer: objToUrlParams,
});

// Interceptors
axiosClient.interceptors.request.use(onRequest);
axiosClient.interceptors.response.use(onResponse, onResponseError);

authService.interceptors.response.use(onResponse, onResponseError);

export default axiosClient;
export { authService };
