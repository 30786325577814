import styled from "@emotion/styled";
import { openModal, setModalMode } from "appStore/modalSlice";
import {
	AddBtn,
	CommonFilterForm,
	Paper,
	PositionSelect,
	RankSelect,
	SearchInput,
	TableWithQuery,
} from "components";
import { PLAN_BILLABLE, PLAN_BILLABLE_EXPORT } from "constants/api";
import { useAppSelector } from "hooks";
import { useEditFunc } from "hooks/useEditFunc";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { billableFormNames, effortNames } from "translations/keys/plan";
import { HeaderInfoObj } from "../components/SmallComponents";
import { DATE_PICKER_PROPS } from "../constants";
import BillableForm from "./compontents/ModalForm";
import { billableColumns } from "./constants";
import { PERMISSION, PermissionProps } from "../../../constants/permissionMap";
import { confirmDelete } from "../../../utils";
import axiosClient from "../../../api";
import { fetchTable } from "../../../appStore/commonSlice";

const PaddingHeader = styled(Paper)`
	padding: 0.625rem 1rem;
`;

function PlanBillable({ permissions }: PermissionProps<{}>) {
	const { t } = useTranslation("plan");
	const dispatch = useDispatch();
	const { handleEdit } = useEditFunc();
	const { id } = useParams();

	const handleAdd = () => {
		dispatch(setModalMode("add"));
		dispatch(openModal());
	};

	const { tableResponse: headerData } = useAppSelector((state) => state.common);

	return (
		<>
			<PaddingHeader className="filter">
				<BillableForm />
				<CommonFilterForm
					style={{
						paddingLeft: 0,
						paddingRight: 0,
						paddingBottom: 0,
					}}
					datePickerProps={DATE_PICKER_PROPS}
				>
					<PositionSelect name={"position"} mode="multiple" />
					<RankSelect name={"rank"} mode="multiple" />
					<SearchInput placeholder="planDetail" />
				</CommonFilterForm>
			</PaddingHeader>
			<TableWithQuery
				testId="plan-billableEffort"
				url={`${PLAN_BILLABLE}?projectKey=${id}`}
				exportParams={{
					url: `${PLAN_BILLABLE_EXPORT}?projectKey=${id}`,
					filename: `${id} ${t("billableEffort")}`,
				}}
				rowKey={effortNames.id}
				columns={billableColumns}
				preHandleData={(data: any) => data?.responsePagingDTO}
				headerBtns={[
					<AddBtn onClick={handleAdd} type="primary" />,
					<HeaderInfoObj
						data={headerData}
						marginLeft="20%"
						testId="plan-billableEffort"
						keyPrefix="billableHeader"
					/>,
				]}
				actionColProps={
					permissions.includes(PERMISSION.EDIT)
						? {
								onEdit: handleEdit,
								onDelete: async (record) => {
									confirmDelete(
										async () => {
											await axiosClient.delete(
												PLAN_BILLABLE + "/" + record[effortNames.id]
											);
											dispatch(fetchTable());
										},
										{
											content: record[billableFormNames.username],
											item: t("member"),
										}
									);
								},
						  }
						: undefined
				}
			/>
		</>
	);
}

export default React.memo(PlanBillable);
