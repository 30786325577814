import { isFunction } from "lodash";
import { Key, ReactNode } from "react";
import { INFINITE } from "../../constants";
import { colProps } from "./columns";
import { MCell, MNestedRow, MRow } from "./components";

export const getCol = (key: string) => {
	const col = colProps[key as keyof typeof colProps] ?? {};
	return {
		...col,
		width: col.width ? col.width + "rem" : col.width,
	};
};

const showKey = (key: string, isSpecific?: boolean) => {
	let isValid = key !== "id";
	if (!isSpecific) {
		isValid &&= key !== "status";
	}
	return isValid;
};

export const numRender = (num?: number) =>
	num && Math.abs(+num) >= INFINITE ? undefined : num;

export const renderNestedRows = (
	data: Array<Record<string, any>>,
	key?: Key,
	isSpecific?: boolean
) => {
	return (
		<MNestedRow key={key}>
			{data.map((metricRow, index) => {
				const row: ReactNode[] = [];
				for (const key in metricRow) {
					if (
						Object.prototype.hasOwnProperty.call(metricRow, key) &&
						showKey(key, isSpecific)
					) {
						const element = metricRow[key];
						if (!Array.isArray(element)) {
							const { render, ...col } = getCol(key);
							row.push(
								<MCell key={key} {...col}>
									{isFunction(render) ? render(element, data) : element}
								</MCell>
							);
						} else {
							row.push(renderNestedRows(element, key, isSpecific));
						}
					}
				}
				return <MRow key={index}>{row}</MRow>;
			})}
		</MNestedRow>
	);
};
